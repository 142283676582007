<template>
  <v-list>
    <draggable
      v-model="fields"
      tag="tbody"
    >
      <tr
        v-for="item in fields"
        :key="item['itemId']"
      >
        <td>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon @click="()=>null">
                mdi-equal
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ getItemText(item) }}
            </v-list-item-title>
          </v-list-item>
        </td>
        <td>
          <v-text-field
            v-model.number="item.price"
            class="px-2"
            label="Additional Price"
            type="number"
            :rules="[]"
            hide-details
            outlined
            dense
          />
        </td>
        <td>
          <v-text-field
            v-model.number="item.limit"
            class="px-2"
            label="Maximum Selectable"
            type="number"
            min="1"
            :rules="[rules.isInteger, rules.isPositive]"
            hide-details
            outlined
            dense
          />
        </td>
      </tr>
    </draggable>
  </v-list>
</template>

<script>
import draggable from 'vuedraggable'
import modelMixin from '@/mixins/modelMixin'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'CustomizerItemsRank',
  components: { draggable },
  mixins: [modelMixin, rulesMixin],
  props: {
    itemText: {
      type: String,
      required: true
    }
  },
  methods: {
    getItemText (item) {
      const keys = this.itemText.split('.')
      let cachedItem = item
      keys.forEach(key => {
        cachedItem = cachedItem ? cachedItem[key] : null
      })
      return cachedItem
    },
    endDrag () {
      this.fields.forEach((instance, index) => {
        instance.rank = index
      })
      this.$emit('input', this.fields)
    }
  }

}
</script>
