<template>
  <CustomizerForm
    :breadcrumbs="breadcrumbs"
    :value="{...defaultNew, customizerItems: []}"
  >
    <template #actions="{form, fields}">
      <v-btn
        :disabled="!form"
        color="success"
        class="mx-2"
        rounded
        @click="create(fields)"
      >
        Create
      </v-btn>
    </template>
  </CustomizerForm>
</template>

<script>
import CustomizerForm from '@/pages/App/MenuCreator/Customizers/__CustomizerForm__'
import { Customizer } from '@/store/models'
import { mapGetters } from 'vuex'
import createMixin from '@/mixins/apiCreateMixin'

export default {
  name: 'CustomizerNew',
  components: { CustomizerForm },
  mixins: [createMixin(Customizer)],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Customizers',
          exact: true,
          to: { name: 'App-MenuCreator-Customizers' }
        },
        {
          text: 'New',
          exact: true,
          to: { name: 'App-MenuCreator-Customizers-New' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['defaultNew'])
  }
}
</script>
