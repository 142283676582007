<template>
  <v-card flat>
    <v-form v-model="form">
      <v-toolbar
        class="mt-2"
        dense
        flat
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon dense>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbs" />
        <v-spacer />
        <slot
          name="actions"
          :form="form"
          :fields="fields"
          :isEqual="isEqual"
        />
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="fields.name"
          :rules="[rules.required]"
          label="Name"
          filled
          outlined
          dense
        />
        <v-text-field
          v-model.number="fields.limit"
          label="Total Max Selectable"
          type="number"
          min="1"
          :rules="[rules.required, rules.isInteger, rules.isPositive]"
          validate-on-blur
          filled
          outlined
          dense
        />
        <v-checkbox
          v-model="fields.required"
          label="Must use all allowed choices ? "
          dense
        />
      </v-card-text>
      <v-card-title>Choices</v-card-title>
      <v-card-text>
        <ManyToManySelector
          v-model="fields.customizerItems"
          :company-id="fields.companyId"
          :establishment-id="fields.establishmentId"
          label="Items"
          :items="items.all()"
          item-text="name"
          item-value="id"
          relation="item"
          multiple
        />
      </v-card-text>
      <v-card-text>
        <CustomizerItemsList
          v-model="fields.customizerItems"
          item-text="item.name"
        />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import CustomizerItemsList from '@/pages/App/MenuCreator/Customizers/__CustomizerItemsRank__'
import formMixin from '@/mixins/formMixin'
import ManyToManySelector from '@/components/ManyToManySelector'

export default {
  name: 'CustomizerForm',
  components: { CustomizerItemsList, ManyToManySelector },
  mixins: [formMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('app', ['items', 'itemOptionGroups', 'customizerItems']),
    ...mapState('app/items', { itemById: 'byId' })
  },
  methods: {
    ...mapActions('app/itemOptionGroups',
      { postItemOptionGroup: 'post', patchItemOptionGroup: 'patch', deleteItemOptionGroup: 'delete' }),
    ...mapActions('app/customizerItems',
      { postOptionGroupItem: 'post', patchOptionGroupItem: 'patch', deleteOptionGroupItem: 'delete' })

  }
}
</script>
