export default function createMixin (Model) {
  return {
    methods: {
      async create (data) {
        const response = await Model.api().post(null, data, { save: false })
        if (response.response.status === 200) {
          response.save()
          this.$router.go(-1)
        }
      }
    }
  }
}
